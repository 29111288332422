import React from "react";
import "./prayerRequest.css";
import {Config} from "../../config/config.js";
import {Checks} from "../../utils/checks.js";

const CLOSE_WAIT = 5000;

const MAKE_REQUEST = '<br/><br/><br/><hr/><a href="https://heraldlight.com?p=1" target="_blank" style="text-decoration: none;"><div style="margin-left: 20%; width: 60%; color: #e65c80; text-shadow: black 1px 1px; font-size: 16px; padding: 16px;">Click here to make your own prayer request</div></a><hr/>'

export class PrayerRequest extends React.Component {

	constructor(props) {
		super(props);

		this.closeCallback = props.closeCallback;

		this.state = {
			first_name: "",
			last_name: "",
			email_address: "",
			message: "",
			disabled: false,
			status: "",
		};

		let host = window.location.host.split(':')[0];
		this.urlPrefix = window.location.protocol + "//" + host + "/";

	}

	componentDidMount() {
		this._first_name.focus();
	}

	componentWillUnmount() {

		if ( this.closeTimeout ) {
			clearTimeout(this.closeTimeout);
		}

	}

	onFirstNameChange = (event) => {
		//console.log("onFirstNameChange()");
		let value = event.target.value.trim();

		if ( Checks.isNameOK(value) || 
			value.length < this.state.first_name.length ) {

			this.setState( 
				{
					first_name: event.target.value.trim(),
					status: '',
				} 
			);

		}

	}

	onLastNameChange = (event) => {
		//console.log("onLastNameChange()");
		let value = event.target.value.trim();


		if ( Checks.isNameOK(value) ||
			value.length < this.state.last_name.length ) {

			this.setState( 
				{
					last_name: event.target.value.trim(),
					status: "",
				} 
			);

		}

	}

	onEmailAddressChange = (event) => {
		//console.log("onEmailAddressChange()");

		this.setState( 
			{
				email_address: event.target.value.trim().toLowerCase(),
				status: "",
			} 
		);

	}

	onMessageChange = (event) => {
		//console.log("onMessageChange()");
		let value = event.target.value;

		if ( Checks.isMessageOK(value) || 
			value.length < this.state.message.length ) {

			this.setState( 
				{
					message: event.target.value,
					status: "",
				} 
			);

		}

	}

	clearFields = (event) => {
		this._first_name.focus();

		this.setState(
			{
				first_name: "",
				last_name: "",
				email_address: "",
				message: "",
				status: "",
			}
		);

		event.preventDefault();
	}

	sendRequest = (event) => {
		console.log("sendRequest()");
		event.preventDefault();
		//event.stopPropagation();

		this.setState({disabled: true});
		setTimeout( () => this.setState({disabled: false}), 2000);

		let isValid = true;
		let status = "";

		if ( this.state.first_name.length < 2 ) {
			isValid = false;
			status = "Enter first name";
			this._first_name.focus();
		} else if ( this.state.last_name.length < 2 ) {
			isValid = false;
			status = "Enter last name";
			this._last_name.focus();
		} else if ( false === Checks.isEmailOK(this.state.email_address) ) {
			isValid = false;
			status = "Enter a valid email address";
			this._email_address.focus();
		} else if ( this.state.message.trim().length < 2 ) {
			isValid = false;
			status = "Enter a  message";
			this._message.focus();
		}

		let signature = "<br/><br/>Thank you for your prayers,<br>" + this.state.first_name

		if ( true === isValid ) {
			let message = '<div style="font-size: 16px">' + 
				this.state.message + signature + MAKE_REQUEST + '</div>';
			console.log("valid");
			let formData = new FormData();
			formData.set("first_name", this.state.first_name);
			formData.set("last_name", this.state.last_name);
			formData.set("email_address", this.state.email_address);
			formData.set("message", message);

			/*
			for ( let k of formData.keys() ) {
				console.log(`${k}: '${formData.get(k)}'`);
			}
			*/

			let options = {
				method: "POST",
				body: formData,
			}

			let url = this.urlPrefix + Config.getPrayerRequestUrl();

			//console.log(url);

			let request = new Request(url, options);
			this.sendPrayerRequest( request );

		} else {
			this.setState({status: status});
		}

	}

	async sendPrayerRequest(request) {
		//console.log("sendPrayerRequest()");

		try {
			let response = await fetch(request);

			if ( response.ok ) {
				//let text = await response.text();
				await response.text();
				//console.log(text);
				this.setState( {status: "The prayer request has been sent"});
				this._doveBox.style.display = "block";
				this.closeTimeout = setTimeout( this.closeCallback, CLOSE_WAIT );
			} else {
				let err = await response.text();
				console.error(err);
				this.setState( {status: err});
			}

		}

		catch(exc) {
			console.error(exc);
			// this.setState( {status: exc } );
		}

	}

	render() {
		let self = this;

		return(

			<div className="prayerRequestObject">
				<div className="doveBox" ref={ e => self._doveBox = e }>
					<img className="sendDove" alt="" src="/images/200.webp"></img>
				</div>

				<div className="prayerRequestTop">

					<div className="prayerRequestHeading">
						Make a Prayer Request
					</div>

					<div className="prayerRequestClose" onClick={this.closeCallback}
						title="Close">
						X
					</div>

				</div>

					<form>

					<div className="prayerRequestRow">
						<div className="prayerRequestLabel">First Name</div>
						<input type="text" className="prayerRequestInput"
							value={this.state.first_name}
							onChange={this.onFirstNameChange}
							ref={e => self._first_name = e}/>
					</div>

					<div className="prayerRequestRow">
						<div className="prayerRequestLabel">Last Name</div>
						<input type="text" className="prayerRequestInput"
							value={this.state.last_name}
							onChange={this.onLastNameChange} 
							ref={ e => self._last_name = e }/>
					</div>

					<div className="prayerRequestRow">
						<div className="prayerRequestLabel">Email</div>
						<input type="email" className="prayerRequestInput"
							placeholder="Your email address" value={this.state.email_address}
							onChange={this.onEmailAddressChange} 
							ref={ e => self._email_address = e }/>
					</div>

					<div className="prayerRequestMessageLabel">Prayer Request</div>
					<textarea className="prayerRequestMessage"
						placeholder="Enter prayer request" rows="5"
							value={this.state.message}
							onChange={this.onMessageChange} 
							ref={ e => self._message = e }
							/>

					<div className="prayerSendRow">
						<button className="prayerRequestButton" 
							disabled={this.state.disabled}
							onClick={this.sendRequest}>Send Request</button>
						<button className="prayerRequestButton clearButton"
							onClick={this.clearFields} >Clear</button>
						<div className="prayerRequestStatus">{this.state.status}</div>
					</div>

					</form>

			</div>
		);

	}

}
